import { Card, Layout, Result, Spin } from "antd";
import { useEffect, useState } from "react";

export default function Login({ children }) {
  const [logged, setLogged] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    async function check() {
      try {
        const response = await fetch(`${process.env.REACT_APP_ENDPOINT}users/me/`, {
          headers: { Authorization: `Token ${window.location.hash.slice(1)}` }
        });
        console.log(response);
        if (response.status !== 200) {
          setError(true);
          setLoading(false);
        } else {
          setError(false);
          setLoading(false);
          setLogged(true);
        }
      } catch(e) {
        console.error(e);
        setError(true);
        setLoading(false);
      }
    }
    check();
  }, []);

  if (loading) {
    return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content>
        <Card style={{ textAlign: 'center' }}>
          <Spin />
        </Card>
      </Layout.Content>
    </Layout>
    );
  }

  if (error) {
    return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content>
        <Card style={{ textAlign: 'center' }}>
          <Result 
            status="error"
            title="Errore"
            subTitle="Link non corretto"
          />
        </Card>
      </Layout.Content>
    </Layout>
    );
  }

  return children;
}